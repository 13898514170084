import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import React, { useEffect, useState } from "react";
import { Line } from 'react-chartjs-2';

const ProxyTraffic = () => {
    ChartJS.register(
        CategoryScale,
        LinearScale,
        PointElement,
        LineElement,
        Title,
        Tooltip,
        Legend
    );

    const [labels, setLabels] = useState([]);
    const [successData, setSuccessData] = useState([]);
    const [errorData, setErrorData] = useState([]);
    const [data, setData] = useState({});
    function limitArraySize(arr, maxSize = 20) {
        while (arr.length > maxSize) {
            arr.shift(); // Remove the first element
        }
        return arr;
    }

    useEffect(() => {
        const intervalId = setInterval(() => {
            const randomNumber1 = Math.floor(Math.random() * 31) + 50;
            const randomNumber2 = Math.floor(Math.random() * 31) + 50;
            // const currentTimeInMilliseconds = new Date().getTime();

            setSuccessData(prevData => limitArraySize([...prevData, randomNumber1]));
            setErrorData(prevData => limitArraySize([...prevData, randomNumber2]));
            setLabels(prevLabels => limitArraySize([...prevLabels, ""]));
            setData({
                labels,
                datasets: [
                    {
                        label: 'Error',
                        data: [...errorData],
                        borderColor: 'rgb(255, 99, 132)',
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                        yAxisID: 'y',
                    },
                    {
                        label: 'Success',
                        data: [...successData],
                        borderColor: 'rgb(53, 162, 235)',
                        backgroundColor: 'rgba(53, 162, 235, 0.5)',
                        yAxisID: 'y1',
                    },
                ],
            });
        }, 1000);

        // Clear the interval when the component unmounts
        return () => clearInterval(intervalId);

    }, [successData, errorData, labels, data]);



    const options = {
        responsive: true,
        maintainAspectRatio: true,
        interaction: {
            mode: 'index',
            intersect: false,
        },
        stacked: false,
        plugins: {
            title: {
                display: true,
                text: 'Traffic Chart',
            },
        },
        scales: {
            y: {
                type: 'linear',
                display: true,
                position: 'left',
            },
            y1: {
                type: 'linear',
                display: true,
                position: 'right',
                grid: {
                    drawOnChartArea: false,
                },
            },
        },
        elements: {
            line: {
                tension: 0.4, // Set the tension to make the line curved
            },
        },
        animation: {
            duration: 0, // Set the animation duration to 0 to disable it
        },
    };


    return (
        <div style={{ maxWidth: '80%', minHeight: '70%', margin: '0 auto' }}>
            {data?.datasets?.length &&
                <Line options={options} data={data} />
            }
        </div>
    );
}

export default ProxyTraffic;
