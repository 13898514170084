import React, { useEffect, useState } from "react";

import ApiServices from "../services/api.services.js";


import { Empty, Spin, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setAuth } from "../redux/commonSlice.js";
import ApiKeyServices from "../services/api-key.services.js";
import AuthService from "../services/auth.services.js";
import DataTable from "./DataTable/DataTable.js";

const ApiKeys = () => {
    const [data, setData] = useState([]);
    const [spinning, setSpinning] = useState(true);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const currentUser = useSelector((state) => state.common.user);

    useEffect(() => {
        const apiCall = async () => {
            try {
                const { data } = await ApiServices.getUsersAllEndpoints(currentUser.id);
                setData(data);
                setSpinning(false);
            } catch (error) {
                notification.error({
                    message: "Error",
                    description: "AccessToken Invalid!"
                });
                AuthService.logout();
                dispatch(setAuth({ isAuthenticating: false, isAuthenticated: false, accessToken: null }))
                setTimeout(() => {
                    navigate('/login');
                }, 2000);
            }
        };
        apiCall();
        return () => setData([]); // This worked for me
    }, [currentUser, dispatch, navigate]);

    const handleDelete = async (index, api_key, user_id, api_id) => {
        try {
            setSpinning(true);
            setData((prevData) => prevData.filter((_, i) => i !== index));

            const { data: result } = await ApiKeyServices.deleteApiKey({ api_key }, { api_id, user_id });
            console.log(result);

            setSpinning(false);
            notification.success({
                message: "Success",
                description: "Api key deleted successfully"
            });
        } catch (error) {
            notification.error({
                message: "Error",
                description: error.message || `Error occurred while deleting api key`
            });
            setSpinning(false);
        }
    };

    const handleEdit = (index, api_key) => {
        setData((prevData) => prevData.map((item, i) => i === index ? { ...item, api_key } : item));
    };

    return (
        <>
            <Spin size="large" spinning={spinning} fullscreen={true} />
            <div className="container">
                {data.length ? <DataTable data={data} onDelete={handleDelete} setSpinning={setSpinning} currentUser={currentUser} onUpdate={handleEdit} source={"ApiList"} /> : (!spinning ? <Empty style={{ marginTop: "30%" }} /> : <></>)}
            </div>
        </>
    );
};

export default ApiKeys;
