import { Button, Empty, Spin, Table, Tag, notification } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setAuth } from '../redux/commonSlice';
import ApiLogService from '../services/api-log.services';
import AuthService from '../services/auth.services';
import DropDown from './DropDown/Dropdown';


const Dashboard = () => {

    const navigate = useNavigate()
    const dispatch = useDispatch();

    const columns = [
        {
            title: 'API Logs',
            children: [
                {
                    title: 'Method',
                    dataIndex: 'method',
                    key: 'method',
                    align: 'center',
                    render: (text) => {
                        // GET , POST, DELETE, PUT
                        const color = text === 'GET' ? 'green' : text === 'POST' ? 'blue' : text === 'DELETE' ? 'red' : 'orange';
                        return <Tag color={color}>{text}</Tag>;
                    },
                },
                {
                    title: 'Endpoint',
                    dataIndex: 'endpoint',
                    key: 'endpoint',
                    align: 'center',
                    render: (text) => {
                        return <pre>{text}</pre>
                    }
                },
                {
                    title: 'Status',
                    dataIndex: 'status_code',
                    key: 'status_code',
                    align: 'center',
                    render: (status_code) => {
                        const color = status_code === 200 ? 'green' : 'red';
                        return <Tag color={color}>{status_code}</Tag>;
                    },
                },
                {
                    title: 'Error',
                    dataIndex: 'message',
                    key: 'message',
                    align: 'center',
                    render: (message) => (message ? <Tag color="red">{message}</Tag> : null),
                },
                {
                    title: 'Time',
                    dataIndex: 'time',
                    align: 'center',
                    key: 'time',
                    render: (timeString) => {
                        //separate date and time from timeString
                        const time = timeString.split(' ')[1];
                        const date = timeString.split(' ')[0];
                        return (<div>
                            <b>{time}</b><br />
                            {date}
                        </div>)
                    }
                },

            ]
        }
    ];

    const [tableData, setTableData] = useState([]);
    const [spinning, setSpinning] = useState(true);
    const [filter, setFilter] = useState({
        type: null,
        status: null
    });

    const [rawData, setRawData] = useState([]);


    const [typeOptions, setTypeOptions] = useState(['CLIENT ERROR', 'SERVER ERROR', 'SUCCESS']);
    const [statusOptions, setStatusOptions] = useState([]);

    const [drop1, setDrop1] = useState("Type");
    const [drop2, setDrop2] = useState("Status");


    const checkErrorStatusCode = (statusCode) => {
        const regex = /^[45]\d{2}$/;

        // Test the error code against the regex
        if (regex.test(statusCode)) {
            return true
        } else {
            return false
        }
    }


    useEffect(() => {
        setSpinning(true);
        const apiCall = async () => {
            try {
                const { data } = await ApiLogService.apiLogService()
                setRawData(data.reverse());
                setSpinning(false);
            } catch (error) {
                notification.error({
                    message: "Error",
                    description: "AccessToken Invalid!"
                });
                AuthService.logout();
                dispatch(setAuth({isAuthenticating: false, isAuthenticated: false, accessToken: null}))
                setTimeout(() => {
                    navigate('/login');
                }, 2000);
            }
        };
        apiCall();
        return () => {
            setRawData([]);
        }
    }, [dispatch, navigate]);



    useEffect(() => {
        const statusOptions = [];

        if (rawData.length === 0) return setTableData([]);

        let tableDataTemp = rawData.map((item, index) => {
            if (!statusOptions.includes(item.status_code)) statusOptions.push(item.status_code);
            return {
                key: index,
                method: item.method,
                endpoint: item.endpoint,
                status_code: item.status_code,
                message: checkErrorStatusCode(item.status_code) ? item.message : "",
                time: moment(item.createdAt).format('YYYY-MM-DD HH:mm:ss'),
            }
        });

        if (filter.type) {
            const temp1 = []
            tableDataTemp.forEach((item, index) => {
                if (rawData[index].status === filter.type) {
                    temp1.push(item);
                }
            });
            tableDataTemp = temp1;
        }

        if (filter.status) {
            const temp1 = []
            tableDataTemp.forEach((item) => {
                if (item.status_code === filter.status) {
                    temp1.push(item);
                }
            });
            tableDataTemp = temp1;
        }


        setTableData(tableDataTemp);

        setStatusOptions(statusOptions);
        setTypeOptions(['CLIENT ERROR', 'SERVER ERROR', 'SUCCESS'])

        return () => {
            setTableData([]);
            setStatusOptions([]);
            setTypeOptions([]);
        }
    }, [filter, rawData]);

    const onChangeFilter = (value, filterVal) => {
        if (filterVal === 1) {
            setDrop1(value);
            setFilter({ ...filter, type: value });
        } else if (filterVal === 2) {
            setDrop2(value);
            setFilter({ ...filter, status: value });
        }
    }

    const handleClearFilter = () => {
        setFilter({
            type: null,
            status: null
        });
        setDrop1("Type");
        setDrop2("Status");
    }

    return (
        <>
            <Spin size="large" spinning={spinning} fullscreen={true} />
            {(rawData.length > 0 && !spinning) ? (
                <div className='container-xl'>
                    {/* make a div which contains elements vertically one by one from left*/}
                    <div className='row'>
                        <div className='col-3'>
                            <DropDown name={drop1} options={typeOptions} onChangeFilter={onChangeFilter} filterVal={1} />
                        </div>
                        <div className='col-3'>
                            <DropDown name={drop2} options={statusOptions} onChangeFilter={onChangeFilter} filterVal={2} />
                        </div>
                        <div className='col-3'>
                            <></>
                        </div>
                        <div className='col-3'>
                            <Button onClick={handleClearFilter} size='large' type='primary'>Clear Filters</Button>
                        </div>
                    </div>
                    {(tableData.length > 0 && !spinning) ? (
                        <Table columns={columns} dataSource={tableData} bordered={false} pagination={{ position: "bottomCenter" }} scroll={true} />
                    ) : (
                        <Empty style={{ marginTop: "30%" }} />
                    )}
                </div>
            ) : (
                <Empty style={{ marginTop: "20%" }} />
            )}
        </>
    );
};
export default Dashboard;