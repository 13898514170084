import axios from "axios";
import AuthVerifyService from "../common/AuthVerify";

const API_URL = "/api/v1/auth/";

const register = (username, email, password) => {
  return axios.post(API_URL + "signup", {
    username,
    email,
    password,
  });
};

const login = (username, password) => {
  return axios
    .post(API_URL + "signin", {
      username,
      password,
    })
    .then((response) => {
      if (response.data.username) {
        localStorage.setItem("user", JSON.stringify(response.data));
      }

      return response.data;
    });
};

const logout = () => {
  if (localStorage.getItem("user")) localStorage.removeItem("user");
  // return axios.post(API_URL + "signout").then((response) => {
  //   return response.data;
  // });
};

const getCurrentUser = () => {
  const user = localStorage.getItem("user");

  if (user) {
    const token = JSON.parse(user).accessToken
    const decodedJwt = AuthVerifyService.parseJwt(token);
    if (decodedJwt?.exp * 1000 < Date.now()) {
      return null;
    }
  }

  if (!user) return null;

  return JSON.parse(user);
};

const AuthService = {
  register,
  login,
  logout,
  getCurrentUser,
}

export default AuthService;
