import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Space } from 'antd';
import React, { useEffect, useState } from 'react';
const DropDown = ({ name, options, onChangeFilter, filterVal }) => {
    const [items, setItems] = useState(options);

    const handleMenuClick = (e) => {
        onChangeFilter(options[e.key], filterVal);
    };

    useEffect(() => {
        const itemsTemp = options.map((option, index) => {
            return {
                label: option,
                key: index,
            }
        })
        setItems(itemsTemp);

        return () => {
            setItems([]);
        }
    }, [name, options]);


    return (<Space wrap>
        {items && items?.length > 0 ? (
            <Dropdown menu={{
                items,
                onClick: handleMenuClick,
            }}>
                <Button style={{ width: "180px" }} size='large' >
                    <Space>
                        {name}
                        <DownOutlined />
                    </Space>
                </Button>
            </Dropdown>
        ) : (
            <></>
        )}
    </Space>
    )
};
export default DropDown;
