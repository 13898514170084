import axios from "axios";
import AuthService from "./auth.services";

const API_URL = "/api/v1/endpoint/";

const getAllEndpoints = () => {
    return axios.get(API_URL + "get-all/true", {
        headers: {
            Authorization: 'Bearer ' + AuthService.getCurrentUser().accessToken,
        }
    });
};

const getUsersAllEndpoints = (userId) => {
    return axios.get(API_URL + "get-all/" + userId, {
        headers: {
            Authorization: 'Bearer ' + AuthService.getCurrentUser().accessToken,
        }
    });
};

const getApisNotOwnedByUser = (userId) => {
    return axios.get(API_URL + "get-not-owned-by-user/" + userId, {
        headers: {
            Authorization: 'Bearer ' + AuthService.getCurrentUser().accessToken,
        }
    });
}

const ApiServices = {
    getAllEndpoints,
    getUsersAllEndpoints,
    getApisNotOwnedByUser
}

export default ApiServices;
