import axios from "axios";
import AuthService from "./auth.services";

const API_URL = "/api/v1/api-key/";

const generateApiKey = (_, data) => {
    return axios.post(API_URL + "add", data, {
        headers: {
            Authorization: 'Bearer ' + AuthService.getCurrentUser().accessToken,
        }
    });
};

const deleteApiKey = (params, _) => {
    return axios.delete(API_URL + "delete/" + params.api_key, {
        headers: {
            Authorization: 'Bearer ' + AuthService.getCurrentUser().accessToken,
        }
    });
};

const getUserApiKeys = (params) => {
    return axios.get(API_URL + "get/" + params.user_id, {
        headers: {
            Authorization: 'Bearer ' + AuthService.getCurrentUser().accessToken,
        }
    });
};

const ApiKeyServices = {
    generateApiKey,
    deleteApiKey,
    getUserApiKeys
}

export default ApiKeyServices;
