import axios from "axios";
import AuthService from "./auth.services";

const API_URL = "/api/v1/get-all-logs";

const apiLogService = () => {
    return axios.get(API_URL, {
        headers: {
            Authorization: 'Bearer ' + AuthService.getCurrentUser().accessToken,
        }
    },);
};


const ApiLogService = {
    apiLogService
}

export default ApiLogService;