import React from "react";
import "./ApiKey.css";

const ApiKey = ({apiKey}) => {
    return (
        <div style={{ height: "100%" }}>
            <table style={{ width: "100%", textAlign: "center", borderCollapse: "collapse" }}>
                <tbody>
                    <tr>
                        <td style={{ textAlign: "left", padding: "8px" }}>
                            <pre style={{ fontSize: "14px", fontWeight: "bold", color: "blue", margin: "0" }}>{apiKey}</pre>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default ApiKey;
