import React from "react";
// import { withRouter } from "react-router-dom";

const parseJwt = (token) => {
  try {
    const info = JSON.parse(atob(token.split(".")[1]).toString('utf-8'))
    if (info) return info;
    return null
  } catch (e) {
    return null;
  }
};

const AuthVerify = (props) => {
  props.history.listen(() => {
    const user = JSON.parse(localStorage.getItem("user"));

    if (user) {
      const decodedJwt = parseJwt(user.accessToken);

      if (decodedJwt.exp * 1000 < Date.now()) {
        props.logOut();
      }
    }
  });

  return <div></div>;
};

const AuthVerifyService = {
  AuthVerify,
  parseJwt
}

export default AuthVerifyService;
