import { Button, Spin, Table, notification } from "antd";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ApiKeyServices from "../services/api-key.services";



const Profile = () => {
  const columns = [
    {
      title: 'API KEY',
      dataIndex: 'api_key',
      key: 'api_key',
      align: 'center',
      render: (message) => (message ? <pre style={{ color: "green", fontSize: "15px", fontWeight: "bold" }}>{message}</pre> : null),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      align: 'center',
      key: 'action',
      render: (_, item) => {
        return <Button type="primary" danger onClick={() => handleKeyDelete(item)}>Delete</Button>
      }
    },
  ];

  const [data, setData] = React.useState([]);

  const { isAuthenticated, isAuthenticating } = useSelector(state => state.common.auth);


  const currentUser = useSelector(state => state.common.user);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [spinning, setSpinning] = React.useState(true);

  const handleKeyGenerate = async () => {
    setSpinning(true);
    try {
      const resData = await ApiKeyServices.generateApiKey(null, { user_id: currentUser.id });
      if (resData.data?.success) {
        notification.success({
          message: 'Success',
          description: "API Key Generated Successfully!"
        });
        const newData = [...data];
        newData.push({ key: newData.length, api_key: resData.data?.apiKey, action: "delete" });
        setData(newData);
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.message || 'Sorry! Something went wrong. Please try again!'
      });
    }
    setSpinning(false);
  }

  const handleKeyDelete = async (item) => {
    setSpinning(true)
    try {
      const deletedRes = await ApiKeyServices.deleteApiKey({ api_key: item.api_key });
      if (deletedRes?.data?.success) {
        notification.success({
          message: 'Success',
          description: deletedRes.data.message
        });
        //remove the key from the table data
        const newData = [...data];
        newData.shift();
        setData(newData);
      }
    } catch (error) {
      notification.error({
        message: 'Error',
        description: error.message || 'Sorry! Something went wrong. Please try again!'
      });
    }
    setSpinning(false);
  }

  useEffect(() => {
    const apiCall = async () => {
      setSpinning(true);
      try {
        const { data } = await ApiKeyServices.getUserApiKeys({ user_id: currentUser.id });
        const keys = data.keys.map((item, index) => {
          return { key: index, api_key: item.key, action: "delete" }
        })
        setData(keys);
      } catch (error) {
        notification.error({
          message: "Error",
          description: "AccessToken Invalid!"
        });
      }
    }
    apiCall();
    setSpinning(false);

    return () => {
      setData([]);
    }
  }, [currentUser, dispatch, navigate, isAuthenticated]);

  return (
    <div className="container">
      <Spin spinning={spinning || isAuthenticating} size="large" fullscreen />
      <div style={{ marginTop: '20px' }}>
        <Button style={{ width: "100%", marginTop: "5%" }} type="primary" size="large" onClick={handleKeyGenerate}>Generate API Key</Button>
      </div>
      <Table columns={columns} dataSource={data} style={{ textAlign: 'center', marginTop: "5%" }} showHeader />
    </div>
  );
};

export default Profile;
