/* eslint-disable jsx-a11y/anchor-is-valid */
import { notification } from 'antd';
import React, { useState } from 'react';
import ApiKeyServices from '../../services/api-key.services';
import ApiKey from '../ApiKey/ApiKey';
import ModalCustom from '../Modal/ModalCustom';
import './DataTable.css';



const DataTable = ({ data, onDelete, onUpdate, currentUser, source, setSpinning }) => {
    const [modalVisible, setModalVisible] = useState(false);
    const [title, setTitle] = useState('');
    const [content, setContent] = useState(<></>);

    const openModal = () => {
        setModalVisible(true);
    }

    const generateApiKey = async (user_id, api_id, api_name, index, deletable = true) => {
        try {
            setSpinning(true);
            const { data: result } = await ApiKeyServices.generateApiKey(null, { user_id });
            setTitle(api_name);
            setContent(
                <ApiKey apiKey={result.apiKey} />
            );

            if (deletable) onDelete(index);
            else onUpdate(index, result.apiKey);

            setSpinning(false);

            notification.success({
                message: 'Success',
                description: deletable ? 'API Key Created.\nCheck your API-KEY list.' : 'API Key Updated successfully.',
                position: 'topRight'
            });

            // openModal();
        } catch (error) {
            console.log(error);
            notification.error({
                message: 'Error',
                description: 'Something went wrong. Please try again later.',
                position: 'topRight'
            });
            setModalVisible(false);
        }
    }

    return (
        <>
            <ModalCustom children={content} Width={1000} Title={title} modalVisibility={modalVisible} setModalVisibility={setModalVisible} />
            <table style={{ width: "100%" }}>
                <thead>
                    <tr>
                        <th>API</th>
                        {
                            source === "home" ? (
                                currentUser && <th>Endpoint</th>
                            ) : (
                                <>
                                    <th>API Key</th>
                                    <th>Endpoint</th>
                                </>
                            )
                        }
                    </tr>
                </thead>
                {source !== "home" ?
                    (<tbody>
                        {data.map((item, index) => (
                            <tr key={index}>
                                <td>
                                    <a href="#" onClick={() => {
                                        setTitle(item.name);
                                        setContent(
                                            <div style={{ height: "100%" }}>
                                                <table style={{ width: "100%", textAlign: "center", borderCollapse: "collapse" }}>
                                                    <tbody>
                                                        <tr>
                                                            <td style={{ textAlign: "left", padding: "15px" }}>
                                                                <h5 style={{ textDecoration: "underline" }}>API Endpoint</h5>
                                                                <pre style={{ fontSize: "14px", fontWeight: "bold", color: "blue", margin: "0" }}>{item.url}</pre>
                                                            </td>
                                                            <td style={{ textAlign: "left", padding: "15px" }}>
                                                                <h5 style={{ textDecoration: "underline" }}>Method</h5>
                                                                <pre style={{ fontSize: "14px", fontWeight: "bold", color: "black", margin: "0" }}>{item.method}</pre>
                                                            </td>
                                                        </tr>
                                                        {/* <tr> */}
                                                        <tr style={{ textAlign: "left", padding: "15px" }}>
                                                            <h5 style={{ textDecoration: "underline" }}>Request Param</h5>
                                                            <pre style={{ margin: "0" }}>{JSON.stringify(JSON.parse(item.params), null, 2)}</pre>
                                                        </tr>
                                                        <tr style={{ textAlign: "left", padding: "15px" }}>
                                                            <h5 style={{ textDecoration: "underline" }}>Request Body</h5>
                                                            <pre style={{ margin: "0" }}>{JSON.stringify(JSON.parse(item.body), null, 2)}</pre>
                                                        </tr>
                                                        {/* </tr> */}
                                                    </tbody>
                                                </table>
                                            </div>
                                        );
                                        openModal();
                                    }}>{item.name}</a>
                                </td>
                                <td>
                                    <button className='button-primary' onClick={() => {
                                        openModal();
                                        setTitle(item.name);
                                        setContent(
                                            <ApiKey apiKey={item.api_key} />
                                        );
                                    }}>Show API Key</button>
                                </td>

                                <td>
                                    <button className='button-secondary' onClick={() => onDelete(index,item.api_key,currentUser.id,item.id)}>Delete</button>
                                    <button className='ml-2 button-success' onClick={() => generateApiKey(currentUser.id, item.id, item.name, index, false)}>Renew</button>
                                </td>
                            </tr>
                        ))}
                    </tbody>) : (
                        <tbody>
                            {data.map((item, index) => (
                                <tr key={index}>
                                    <td>
                                        <a href="#" onClick={() => {
                                            setTitle(item.name);
                                            setContent(
                                                <div style={{ height: "100%" }}>
                                                    <table style={{ width: "100%", textAlign: "center", borderCollapse: "collapse" }}>
                                                        <tbody>
                                                            <tr>
                                                                <td style={{ textAlign: "left", padding: "15px" }}>
                                                                    <h5 style={{ textDecoration: "underline" }}>API Endpoint</h5>
                                                                    <pre style={{ fontSize: "14px", fontWeight: "bold", color: "blue", margin: "0" }}>{item.url}</pre>
                                                                </td>
                                                                <td style={{ textAlign: "left", padding: "15px" }}>
                                                                    <h5 style={{ textDecoration: "underline" }}>Method</h5>
                                                                    <pre style={{ fontSize: "14px", fontWeight: "bold", color: "black", margin: "0" }}>{item.method}</pre>
                                                                </td>
                                                            </tr>
                                                            {/* <tr> */}
                                                            <tr style={{ textAlign: "left", padding: "15px" }}>
                                                                <h5 style={{ textDecoration: "underline" }}>Request Param</h5>
                                                                <pre style={{ margin: "0" }}>{JSON.stringify(JSON.parse(item.params), null, 2)}</pre>
                                                            </tr>
                                                            <tr style={{ textAlign: "left", padding: "15px" }}>
                                                                <h5 style={{ textDecoration: "underline" }}>Request Body</h5>
                                                                <pre style={{ margin: "0" }}>{JSON.stringify(JSON.parse(item.body), null, 2)}</pre>
                                                            </tr>
                                                            {/* </tr> */}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            );
                                            openModal();
                                        }}>{item.name}</a>
                                    </td>
                                    {currentUser && (
                                        <td>
                                            {/* <button className='button-primary' onClick={() => {
                                                generateApiKey(currentUser.id, item.id, item.name, index);
                                            }}>Generate API Key</button> */}
                                            {item.url}
                                        </td>
                                    )}
                                </tr>
                            ))}
                        </tbody>
                    )
                }
            </table>
        </>
    );
};

export default DataTable;
