import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import CheckButton from "react-validation/build/button";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";

import { useDispatch, useSelector } from "react-redux";
import { setAuth, setUser } from "../redux/commonSlice";
import AuthService from "../services/auth.services";

const required = (value) => {
  if (!value) {
    return (
      <div className="invalid-feedback d-block">
        This field is required!
      </div>
    );
  }
};

const Login = () => {
  const form = useRef();
  const checkBtn = useRef();

  const dispatch = useDispatch();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");

  const currentUser = useSelector(state => state.common.user);

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const handleLogin = async (e) => {
    e.preventDefault();

    setMessage("");
    setLoading(true);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {

      try {
        dispatch(setAuth({ isAuthenticating: true, isAuthenticated: false }));
        const authRes = await AuthService.login(username, password);

        const currentUser = AuthService.getCurrentUser();
        dispatch(setUser(currentUser));
        dispatch(setAuth({ isAuthenticating: false, isAuthenticated: !!currentUser }));

        if (authRes?.accessToken) {
          setTimeout(() => {
            navigate("/api-logs");
          }, 2000);
        }
        else {
          const resMessage =
            (authRes.response &&
              authRes.response.data &&
              authRes.response.data.message) ||
            authRes.message ||
            authRes.toString();

          setLoading(false);
          setMessage(resMessage);
        }
      } catch (error) {
        console.log(error);
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setLoading(false);
        setMessage(resMessage);
      }
    } else {
      setLoading(false);
    }
  };

  const navigate = useNavigate();


  useEffect(() => {
    if (currentUser) {
      setLoading(false);
      navigate("/api-logs");
    }
    setLoading(false);
    return () => {
      setLoading(false);
    }
  }, [currentUser, navigate]);

  return (
    <div className="col-md-12" style={{ marginTop: "10%" }}>
      <div className="card card-container">
        <img
          src="//ssl.gstatic.com/accounts/ui/avatar_2x.png"
          alt="profile-img"
          className="profile-img-card"
        />

        <Form onSubmit={handleLogin} ref={form}>
          <div className="form-group">
            <label htmlFor="username">Username</label>
            <Input
              type="text"
              className="form-control"
              name="username"
              value={username}
              onChange={onChangeUsername}
              validations={[required]}
            />
          </div>

          <div className="form-group">
            <label htmlFor="password">Password</label>
            <Input
              type="password"
              className="form-control"
              name="password"
              value={password}
              onChange={onChangePassword}
              validations={[required]}
            />
          </div>

          <div className="form-group">
            <button className="btn btn-primary btn-block" disabled={loading}>
              {loading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
              <span>Login</span>
            </button>
          </div>

          {message && (
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                {message}
              </div>
            </div>
          )}
          <CheckButton style={{ display: "none" }} ref={checkBtn} />
        </Form>
      </div>
    </div>
  );
};

export default Login;
