/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/jsx-no-comment-textnodes */
import React from "react";
import { Link } from 'react-router-dom';

const SideNav = ({ userId }) => {
    // const [content, setContent] = useState("");
    return (
        <div className="sidenav" style={{ width: "135px" }}>
            <Link to={"/api-list"} style={{ fontSize: "15px", color : "White" }}>API List</Link>
            <Link to={"/api-logs"} style={{ fontSize: "15px", marginTop: "10%", color : "White" }}>API History</Link>
            {/* <a href={"https://documenter.getpostman.com/view/13018982/2s9YeEcsJk#f3f4f819-9590-430a-bae6-1a23b8325af2"} target="_blank" style={{ fontSize: "15px", marginTop : "10%" , color : "White"}} rel="noreferrer">Documentation</a> */}
        </div>
    );
};

export default SideNav;


