import React, { useEffect, useState } from "react";

import { Empty, Spin, notification } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setAuth } from "../redux/commonSlice.js";
import ApiServices from "../services/api.services.js";
import AuthService from "../services/auth.services.js";
import DataTable from "./DataTable/DataTable.js";

const ApiList = () => {
  const [data, setData] = useState([]);

  const { isAuthenticating, isAuthenticated } = useSelector((state) => state.common.auth);

  const [spinning, setSpinning] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentUser = useSelector((state) => state.common.user);

  useEffect(() => {
    const apiCall = async () => {

      try {
        if (currentUser?.id) {
          // const { data: userData } = await ApiServices.getApisNotOwnedByUser(currentUser.id);
          // setData(userData);
          const { data: allData } = await ApiServices.getAllEndpoints();
          setData(allData);
        } else {
          const { data: allData } = await ApiServices.getAllEndpoints();
          setData(allData);
        }
        setSpinning(false);
      } catch (error) {
        notification.error({
          message: "Error",
          description: "AccessToken Invalid!"
        });
        AuthService.logout();
        dispatch(setAuth({ isAuthenticating: false, isAuthenticated: false, accessToken: null }))
        setTimeout(() => {
          navigate('/login');
        }, 2000);
      }

    };
    apiCall();

  }, [currentUser, dispatch, navigate]);


  const handleDelete = (index) => {
    setData((prevData) => prevData.filter((_, i) => i !== index));
  };

  return (
    <>
      <Spin size="large" spinning={spinning} fullscreen={true} />
      <div className="container">
        {(data.length && currentUser?.id && !spinning && isAuthenticated && !isAuthenticating) ? <DataTable data={data} onDelete={handleDelete} currentUser={currentUser} source={"home"} setSpinning={setSpinning} /> : (!spinning ? <Empty style={{ marginTop: "30%" }} /> : <></>)}
      </div >
    </>
  );
};

export default ApiList;
