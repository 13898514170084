import { createSlice } from '@reduxjs/toolkit';

export const commonSlice = createSlice({

    name: 'common',

    initialState: {
        user: null,
        auth: {
            isAuthenticated: false,
            isAuthenticating: true,
            accessToken: null,
        },
    },


    reducers: {
        setUser: (state, action) => {
            state.user = { ...action.payload };
        },
        setAuth: (state, action) => {
            state.auth = { ...action.payload };
        },
    },
});



export const {
    setUser,
    setAuth
} = commonSlice.actions;

export default commonSlice.reducer;