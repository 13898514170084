import React from "react";

import { Modal } from "antd";

import { CloseCircleTwoTone } from "@ant-design/icons";

const componentName = ({ children, Width, Title, modalVisibility, setModalVisibility }) => {

    return (
        <Modal
            open={modalVisibility}
            onCancel={() => setModalVisibility(false)}
            centered={true}
            footer={null}
            closeIcon={
                <CloseCircleTwoTone
                    twoToneColor="#bfbfbf"
                    style={{ fontSize: "25px" }}
                />
            }
            style={{
                overflowX: "hidden",
                borderRadius: "5px",
            }}
            width={Width}
            zIndex={10000}
            className="shadow-sm bg-body rounded"
        >
            <h1 style={{ textAlign: "center", marginBottom: "30px", fontSize: "20px" }}>
                {Title}
            </h1>

            {children}
        </Modal>
    );
};

export default componentName;