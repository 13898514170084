import "bootstrap/dist/css/bootstrap.min.css";
import React, { useEffect } from "react";
import { Link, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";

import AuthService from "./services/auth.services";

import ApiList from "./components/ApiList";
import Login from "./components/Login";
import Profile from "./components/Profile";
import Register from "./components/Register";
import SideNav from "./components/SideNavBar";

import { useDispatch, useSelector } from "react-redux";
import PrivateRoute from "./common/PrivateRoute";
import ApiKeys from "./components/ApiKeys";
import Dashboard from "./components/Dashboard";
import PageNotFound from "./components/PageNotFound";
import ProxyTraffic from "./components/ProxyTraffic";
import { setAuth, setUser } from "./redux/commonSlice";

const App = () => {
  // const [showModeratorBoard, setShowModeratorBoard] = useState(false);
  // const [showAdminBoard, setShowAdminBoard] = useState(false);
  const currentUser = useSelector(state => state.common.user);
  const isAuthenticating = useSelector(state => state.common.auth.isAuthenticating);
  const isAuthenticated = useSelector(state => state.common.auth.isAuthenticated);


  const dispatch = useDispatch();


  useEffect(() => {
    const user = AuthService.getCurrentUser();
    if (user) {
      dispatch(setUser(user));
      // setShowModeratorBoard(user.roles.includes("ROLE_MODERATOR"));
      // setShowAdminBoard(user.roles.includes("ROLE_ADMIN"));
    }
    dispatch(setAuth({ isAuthenticating: false, isAuthenticated: !!user }));




    // EventBus.on("logout", () => {
    //   logOut();
    // });

    // return () => {
    //   EventBus.remove("logout");
    // };
    // eslint-disable-next-line no-use-before-define
  }, [dispatch]);

  const logOut = () => {
    AuthService.logout();
    setTimeout(() => {
      dispatch(setUser(null));
      dispatch(setAuth({ isAuthenticating: false, isAuthenticated: false }));
    }, 1000);
    // setShowModeratorBoard(false);
    // setShowAdminBoard(false);
  };





  return (
    <div>

      <nav className="navbar navbar-expand navbar-dark bg-dark site-header">
        <Link to={"/profile"} className="navbar-brand">
          OneChain-OpenAPI
        </Link>
        <div className="navbar-nav mr-auto">
          {/* <li className="nav-item">
            <Link to={"/home"} className="nav-link">
              Home
            </Link>
          </li> */}

          {/* {showModeratorBoard && (
            <li className="nav-item">
              <Link to={"/mod"} className="nav-link">
                Moderator Board
              </Link>
            </li>
          )}

          {showAdminBoard && (
            <li className="nav-item">
              <Link to={"/admin"} className="nav-link">
                Admin Board
              </Link>
            </li>
          )} */}

          {/* {currentUser && (
            <li className="nav-item">
              <Link to={"/user"} className="nav-link">
                User
              </Link>
            </li>
          )} */}
        </div>

        {currentUser?.id ? (
          <div className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link to={"/profile"} className="nav-link" style={{ fontSize: "16px", color: "White" }}>
                <b>{currentUser?.username}</b>
              </Link>
            </li>
            <li className="nav-item">
              <a href="/login" className="nav-link" onClick={logOut}>
                <u>LogOut</u>
              </a>
            </li>
          </div>
        ) : (
          <div className="navbar-nav ml-auto">
            <li className="nav-item">
              <Link to={"/login"} className="nav-link">
                <u>Login</u>
              </Link>
            </li>

            <li className="nav-item">
              <Link to={"/register"} className="nav-link">
                <u>SignUp</u>
              </Link>
            </li>
          </div>
        )}
      </nav>

      <div style={{ width: "100%", marginTop: "5%", marginBottom: "2%" }}>
        <Routes>
          <Route exact path={"/login"} element={<Login />} />
          <Route exact path={"/register"} element={<Register />} />

          <Route exact path={"/"} element={<Navigate to="/profile" />} />
          <Route exact path={"/api-logs"} element={<PrivateRoute isAuthenticating={isAuthenticating} isAuthenticated={isAuthenticated} ><Dashboard /></PrivateRoute>} />
          <Route exact path={"/traffic"} element={<PrivateRoute isAuthenticating={isAuthenticating} isAuthenticated={isAuthenticated}><ProxyTraffic /></PrivateRoute>} />
          <Route exact path={"/api-list"} element={<PrivateRoute isAuthenticating={isAuthenticating} isAuthenticated={isAuthenticated}><ApiList /></PrivateRoute>} />
          <Route exact path={"/api-keys"} element={<PrivateRoute isAuthenticating={isAuthenticating} isAuthenticated={isAuthenticated}><ApiKeys /></PrivateRoute>} />
          <Route exact path={"/profile"} element={<PrivateRoute isAuthenticating={isAuthenticating} isAuthenticated={isAuthenticated}><Profile /></PrivateRoute>} />
          <Route path="*" element={<PageNotFound />} />

          {/* <Route path="/user" element={<BoardUser />} /> */}
          {/* <Route path="/mod" element={<BoardModerator />} />
          <Route path="/admin" element={<BoardAdmin />} /> */}
        </Routes>
      </div>


      {currentUser ? (
        <SideNav userId={currentUser?.id} />
      ) : (
        <div></div>
      )}

    </div>
  );
};

export default App;
