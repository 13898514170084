import { Spin } from 'antd';
import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ isAuthenticated, children, isAuthenticating }) => {
    return isAuthenticating ?
        <Spin size="large" spinning={isAuthenticating} fullscreen={true} />
        : (isAuthenticated ? children : <Navigate to="/login" />);
};

export default PrivateRoute;